import { useTranslation } from "react-i18next";

const Header = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-3 logo">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logo-green.png`}
              />
            </div>
            {/* {
                            i18n.language !== "en" ?
                                <div className="col-1" style={{paddingTop: "40px"}}>
                                    <button className="langBtn" onClick={()=>i18n.changeLanguage("en")}>
                                        <div style={{display:"flex",justifyContent:"space-between"}}>
                                            <img style={{margin:"5px",width:"20px",height:"22px"}} src={`${process.env.PUBLIC_URL}/assets/images/en.gif`}/>
                                            <span style={{color: "#005159",margin:"5px"}}>English</span>
                                        </div>
                                    </button>
                                </div> :

                                <div className="col-1" style={{paddingTop: "40px"}}>
                                    <button className="langBtn" onClick={()=>i18n.changeLanguage("ar")}>
                                        <div style={{display:"flex",justifyContent:"space-between"}}>
                                            <img style={{margin:"5px",width:"20px",height:"22px"}} src={`${process.env.PUBLIC_URL}/assets/images/ar.gif`}/>
                                            <span style={{color: "#005159",margin:"5px"}}>Arabic</span>
                                        </div>
                                    </button>
                                </div>
                        } */}
            <div className="col-8 text-right">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/header-right2.png`}
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
