import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const OneChoiceQuestion = ({
  question: { textArabic, textEnglish, options, order, id },
  setQuestionAnswer,
}) => {
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(null);
  }, [id]);

  const handleRadioChange = (option) => {
    setSelectedOption(option.value);
    setQuestionAnswer(option.value, option.nextQuestionId);
  };

  return (
    <section className="section section-lg">
      <div className="container rborder oneChoice">
        <div>
          <div>
            <h3>
              {`${order}- `}
              {i18n.language === "en" ? textEnglish : textArabic}
            </h3>
            <div className="row">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="col-md-6 oneChoiceOption"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    id={`radio-${index}`}
                    name={`radio-${id}`}
                    type="radio"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={() => handleRadioChange(option)}
                    style={{ flexShrink: 0 }}
                  />
                  <label
                    htmlFor={`radio-${index}`}
                    style={{
                      marginLeft: "8px",
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {i18n.language === "en"
                      ? option.textEnglish
                      : option.textArabic}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OneChoiceQuestion;
