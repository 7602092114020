import { useTranslation } from "react-i18next";
import { useState } from "react";

const NumericOneChoice = ({
  question: {
    textArabic,
    textEnglish,
    options,
    leftTextArabic,
    leftTextEnglish,
    rightTextArabic,
    rightTextEnglish,
  },
  setQuestionAnswer,
}) => {
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);

  const getOptionClass = (value) => {
    let baseClass = "";
    switch (value) {
      case "1":
        baseClass = "one";
        break;
      case "2":
        baseClass = "two";
        break;
      case "3":
        baseClass = "three";
        break;
      case "4":
        baseClass = "four";
        break;
      case "5":
        baseClass = "five";
        break;
      case "6":
        baseClass = "six";
        break;
      case "7":
        baseClass = "seven";
        break;
      case "8":
        baseClass = "eight";
        break;
      case "9":
        baseClass = "nine";
        break;
      case "10":
        baseClass = "ten";
        break;
      default:
        break;
    }
    return selectedOption === value ? `${baseClass} selected` : baseClass;
  };

  const handleOptionClick = (value, nextQuestionId) => {
    setSelectedOption(value);
    setQuestionAnswer(value, nextQuestionId);
  };

  return (
    <section className="section section-lg">
      <div className="container rborder">
        <h3>{i18n.language === "en" ? textEnglish : textArabic}</h3>
        <div className="number-btn-section">
          <div className="btn-label-text">
            <span className={i18n.language === "en" ? "left" : "right"}>
              {i18n.language === "en" ? leftTextEnglish : leftTextArabic}
            </span>
            <span className={i18n.language === "en" ? "right" : "left"}>
              {i18n.language === "en" ? rightTextEnglish : rightTextArabic}
            </span>
          </div>
          <div>
            {options.map((option) => (
              <button
                className={getOptionClass(option.value)}
                type="button"
                onClick={() =>
                  handleOptionClick(option.value, option.nextQuestionId)
                }
                key={option.value}
              >
                {i18n.language === "en"
                  ? option.textEnglish
                  : option.textArabic}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NumericOneChoice;
